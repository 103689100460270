import { Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

const GiftsCarousel = () => {
  return (
    <Swiper
      className="swiper-gifts-natal"
      navigation
      spaceBetween={16}
      breakpoints={{
        1440: {
          slidesPerView: 3,
        },
        1244: {
          slidesPerView: 3,
        },
        1100: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        425: {
          slidesPerView: 2,
        },
        360: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 2,
        },
      }}
      lazy={{
        loadPrevNext: true,
        loadPrevNextAmount: 1,
      }}
      modules={[Navigation, Lazy]}
    >
      <SwiperSlide>
        <div className="gifts-carousel-container">
          <a href="https://www.decathlon.com.br/collection/?q=932&O=OrderByTopSaleDESC">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/presentes-f-desk.png"
              alt="Presente | Decathlon"
            />
            <div className="text-wrapper">
              <p>presentes</p>
              <h2>femininos</h2>
            </div>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="gifts-carousel-container">
          <a href="https://www.decathlon.com.br/collection/?q=933&O=OrderByTopSaleDESC">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/presentes-m-desk.png"
              alt="Presente | Decathlon"
            />
            <div className="text-wrapper">
              <p>presentes</p>
              <h2>masculinos</h2>
            </div>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="gifts-carousel-container">
          <a href="https://www.decathlon.com.br/collection/?q=934&O=OrderByTopSaleDESC">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/presentes-i-desk.png"
              alt="Presente | Decathlon"
            />
            <div className="text-wrapper">
              <p>presentes</p>
              <h2>infantis</h2>
            </div>
          </a>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default GiftsCarousel
